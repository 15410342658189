





























































































































































































































































	@import 'vue-apps-assets/styles/scss/vars.scss';
    #account-box {
        padding: 6px 10px;
        display: flex;
        height: $headerHeight;
        max-height: $headerHeight;
        width: 100%;
        max-width: $sidebar-width;
        text-align:left;
        letter-spacing: 0.2px;
        font-weight: 500 !important ;
        #account-info {
            margin-left: 8px;
            color: var(--v-text-lighten1) !important;
            font-size: 10px !important;
            line-height: 14px;
        }
    }
    #account-popup-content {
        padding: 44px 40px 30px 40px !important;
        #account-popup-info {
            margin-top: 6px;
            margin-bottom: 20px;
            color: var(--v-text-base) !important;
            .account-information {
                margin-top: 15px;
            }
        }
        #account-popup-links {
            margin-top: 20.5px;
            height: 118px;
            div {
                color: var(--v-primary-base) !important;
                margin-top: 9.5px;
                margin-bottom: 9.5px;
            }
        }
        #version {
            margin-top: 25px;
            font-size: 10px;
            line-height: 16px;
            color: var(--v-text-lighten1) !important;
        }
    }
    #lang-switch-btn {
        min-width: 0px !important;
    }
    .language-switcher {
        margin-top: 6px;
        margin-bottom: 6px;
        color: var(--v-text-base) !important;
    }
    .rounded-borders {
        border-radius: 30px !important;
        .v-menu__content {
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15), 0px 10px 30px rgba(0, 0, 0, 0.25) !important;
            border-radius: 30px !important;
            z-index: 10 !important;
        }
    }
    #account-popup-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }
