







































































.splash-buttons {
    display: flex;
    justify-content: center;
}
